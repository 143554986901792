import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/User/Desktop/SignPenFreeWorking/SignPenFree/docs/public/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "webhooks"
    }}>{`Webhooks`}</h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      